
/**
 * @roxi/routify 2.18.8
 * File generated Thu Feb 06 2025 04:13:19 GMT+0100 (Central European Standard Time)
 */

export const __version = "2.18.8"
export const __timestamp = "2025-02-06T03:13:19.238Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/admin/audit",
          "id": "_admin_audit",
          "component": () => import('../src/pages/admin/audit.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/ciselniky",
          "id": "_admin_ciselniky",
          "component": () => import('../src/pages/admin/ciselniky.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/emaily",
          "id": "_admin_emaily",
          "component": () => import('../src/pages/admin/emaily.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/externiste",
          "id": "_admin_externiste",
          "component": () => import('../src/pages/admin/externiste.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/import",
          "id": "_admin_import",
          "component": () => import('../src/pages/admin/import.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/nastaveni",
          "id": "_admin_nastaveni",
          "component": () => import('../src/pages/admin/nastaveni.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/osoby",
          "id": "_admin_osoby",
          "component": () => import('../src/pages/admin/osoby.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/sablony",
          "id": "_admin_sablony",
          "component": () => import('../src/pages/admin/sablony.svelte').then(m => m.default)
        }
      ],
      "path": "/admin"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/externi/:id",
          "id": "_externi__id",
          "component": () => import('../src/pages/externi/[id].svelte').then(m => m.default)
        }
      ],
      "path": "/externi"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/hodnoceni/:id",
          "id": "_hodnoceni__id",
          "component": () => import('../src/pages/hodnoceni/[id].svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/hodnoceni/create",
          "id": "_hodnoceni_create",
          "component": () => import('../src/pages/hodnoceni/create.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/hodnoceni/index",
          "id": "_hodnoceni_index",
          "component": () => import('../src/pages/hodnoceni/index.svelte').then(m => m.default)
        }
      ],
      "path": "/hodnoceni"
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/kzopatreni/:id",
          "id": "_kzopatreni__id",
          "component": () => import('../src/pages/kzopatreni/[id].svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/kzopatreni/index",
          "id": "_kzopatreni_index",
          "component": () => import('../src/pages/kzopatreni/index.svelte').then(m => m.default)
        }
      ],
      "path": "/kzopatreni"
    },
    {
      "isPage": true,
      "path": "/nau",
      "id": "_nau",
      "component": () => import('../src/pages/nau.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/navrhy/:id",
          "id": "_navrhy__id",
          "component": () => import('../src/pages/navrhy/[id].svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/navrhy/index",
          "id": "_navrhy_index",
          "component": () => import('../src/pages/navrhy/index.svelte').then(m => m.default)
        }
      ],
      "path": "/navrhy"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/programy/:id",
          "id": "_programy__id",
          "component": () => import('../src/pages/programy/[id].svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/programy/index",
          "id": "_programy_index",
          "component": () => import('../src/pages/programy/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/programy/reject",
          "id": "_programy_reject",
          "component": () => import('../src/pages/programy/reject.svelte').then(m => m.default)
        }
      ],
      "path": "/programy"
    },
    {
      "isPage": true,
      "path": "/test",
      "id": "_test",
      "component": () => import('../src/pages/test.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/zadosti/:id",
          "id": "_zadosti__id",
          "component": () => import('../src/pages/zadosti/[id].svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/zadosti/create",
          "id": "_zadosti_create",
          "component": () => import('../src/pages/zadosti/create.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/zadosti/index",
          "id": "_zadosti_index",
          "component": () => import('../src/pages/zadosti/index.svelte').then(m => m.default)
        }
      ],
      "path": "/zadosti"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/zamitnute/:id",
          "id": "_zamitnute__id",
          "component": () => import('../src/pages/zamitnute/[id].svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/zamitnute/index",
          "id": "_zamitnute_index",
          "component": () => import('../src/pages/zamitnute/index.svelte').then(m => m.default)
        }
      ],
      "path": "/zamitnute"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/zmenasp/:id",
          "id": "_zmenasp__id",
          "component": () => import('../src/pages/zmenasp/[id].svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/zmenasp/create",
          "id": "_zmenasp_create",
          "component": () => import('../src/pages/zmenasp/create.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/zmenasp/index",
          "id": "_zmenasp_index",
          "component": () => import('../src/pages/zmenasp/index.svelte').then(m => m.default)
        }
      ],
      "path": "/zmenasp"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

